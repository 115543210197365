import PropTypes from "prop-types";
import {Container} from "react-bootstrap";
import {getProducts} from "@utils/product";
import Masonry from "react-masonry-component";
import SectionTitle from "@components/ui/section-title";
import {ProductThree as Product} from "@components/product";

const HomeTwelveTrending = ({products}) => {
    const trendingProducts = getProducts(products, 'featured', 10);

    if (!trendingProducts.length) {
        return <></>;
    }

    return (
        <div className="container-indent0">
            <Container className={"max-w-1800"}>
                <SectionTitle
                    title="TRENDING"
                    content="LOS MAS VISTOS DE LA SEMANA"
                />

                <div className="tt-product-listing-masonry">
                    <Masonry className="tt-product-init tt-add-item">
                        {trendingProducts.map((product, i) => (
                            <div key={product.id} className={`element-item ${i === 2 || i === 5 ? 'double-size' : ''}`}>
                                <Product
                                    page="home"
                                    product={product}
                                />
                            </div>
                        ))}
                    </Masonry>
                </div>
            </Container>
        </div>
    );
};

HomeTwelveTrending.propTypes = {
    products: PropTypes.array.isRequired
}

export default HomeTwelveTrending;